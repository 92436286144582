import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthorizationServiceRESTCompanyService, CreateCompany, ReadCompany, UpdateCompany } from '../authorization/generated';
import { Update } from '@ngrx/entity';

@Injectable({ providedIn: 'root' })
export class CompanyDataService extends DefaultDataService<ReadCompany> {
    constructor(
        http: HttpClient,
        httpUrlGenerator: HttpUrlGenerator,
        private companyApi: AuthorizationServiceRESTCompanyService
    ) {
        super('ReadCompany', http, httpUrlGenerator);
    }

    getAll(): Observable<ReadCompany[]> {
        return this.companyApi.getAllCompanies1({});
    }

    getById(code: string): Observable<ReadCompany> {
        return this.companyApi.getCompanyByCode({ code });
    }

    add(entity: CreateCompany): Observable<ReadCompany> {
        return this.companyApi.createCompany({ createCompany: entity });
    }

    update(update: Update<ReadCompany>): Observable<ReadCompany> {
        const company: ReadCompany = {
            ...update.changes,
            region: update.changes.region || null,
            datacenters: update.changes.datacenters || []
        };
        return this.companyApi.updateCompany({ code: company.code, updateCompany: company as UpdateCompany });
    }

    upsert(entity: ReadCompany): Observable<ReadCompany> {
        const company: any = {
            ...entity,
            region: entity.region || null,
            datacenters: entity.datacenters || []
        };
        return this.companyApi.updateCompany({ code: entity.code, updateCompany: company as UpdateCompany });
    }

    delete(key: string): Observable<any> {
        return this.companyApi.deleteCompaniesByCode({ code: [key] });
    }
}
